import { Component } from '@angular/core';

@Component({
  selector: 'ngx-footer',
  styleUrls: ['./footer.component.scss'],
  template: `
    <span class="created-by">
      Created with ♥ by <b><a href="mailto:7mmdmo7@gmail.com" target="_blank">7mmdmo7</a></b>
      Updated by <b><a href="mailto:yassinekrika2016@gmail.com" target="_blank">yassinekrika</a></b> | 2023/2024<span class="mx-2">| v0.044</span>
    </span>
    <div class="socials">
      <a href="https://www.facebook.com/WeeWeedelivery/" target="_blank" >
        <i class="fa-brands fa-facebook-f fa-xs"></i>
      </a>
      <a href="https://instagram.com/weewee.delivery?utm_medium=copy_link" target="_blank">
        <i class="fa-brands fa-instagram fa-xs"></i>
      </a>
      <a href="https://www.linkedin.com/company/weewee-delivery" target="_blank">
        <i class="fa-brands fa-linkedin-in fa-xs"></i>
      </a>
    </div>
  `,
})
export class FooterComponent {
}
