import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject, Observable, timer, from } from 'rxjs';
import { map, tap, filter } from 'rxjs/operators';

import { Router } from '@angular/router';
import { NbGlobalPhysicalPosition, NbToastrService } from '@nebular/theme';

import { Auth, authState, signInWithEmailAndPassword, signOut } from '@angular/fire/auth';
import { Firestore, collectionData, collection, getDoc, doc } from '@angular/fire/firestore'


import { UserAuth } from './models/user-auth-model';


//
import { Driver } from './models/driver-model';
import { Trader } from './models/trader-model';

@Injectable({
  providedIn: 'root'
})
export class Auth99Service {

  mUserAuth: UserAuth | null = null;
  mUserAuth$: BehaviorSubject<UserAuth | null> = new BehaviorSubject<UserAuth | null>(null);
  mAuthBootingComplete: boolean = false;
  mAuthBootingComplete$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  //
  mAuthBootingCall: boolean = true;
  constructor(
    private aAuth: Auth,
    private aFirestore: Firestore,
    private router: Router,
    private toastrService: NbToastrService,
  ) {
    /*let aUserAuth: any = localStorage.getItem('mUserAuth');
    aUserAuth = JSON.parse(aUserAuth);
    console.log("Auth99Service*constructor", aUserAuth);
    if (aUserAuth && aUserAuth.uid && aUserAuth.role) {
      this.setCurrentUserAuth(aUserAuth);
    } else {
      this.setCurrentUserAuth(null);
    }*/
    //
    authState(aAuth).subscribe((user: any) => {
      console.log("Auth99Service*this.afAuth.authState", user);
      if (user) {
        let userIsActive = "";
        let userRole = "";
        let userWilaya = "";
        let userFullName = "";
        //
        if (user.reloadUserInfo && user.reloadUserInfo.customAttributes) {
          let customAttributes = JSON.parse(user.reloadUserInfo.customAttributes);
          userIsActive = customAttributes && customAttributes.active ? customAttributes.active : '';
          userRole = customAttributes && customAttributes.role ? customAttributes.role : '';
          userWilaya = customAttributes && customAttributes.wilaya ? customAttributes.wilaya : '';
          userFullName = customAttributes && customAttributes.fullName ? customAttributes.fullName : '';
        }
        let aUserAuth = new UserAuth({
          uid: user.uid,
          displayName: user.displayName,
          phoneNumber: user.phoneNumber,
          email: user.email,
          active: userIsActive,
          role: userRole,
          wilaya: userWilaya,
          fullName: userFullName,
        });
        if (aUserAuth && aUserAuth.uid && aUserAuth.role) {
          this.setCurrentUserAuth(aUserAuth);
        } else {
          this.setCurrentUserAuth(null);
        }
      } else {
        this.setCurrentUserAuth(null);
      }
    });
  }

  getCurrentUserAuth(): UserAuth {
    return this.mUserAuth;
  }

  getCurrentUserAuth$() {
    return this.mUserAuth$;
  }

  isAuthenticated(): boolean {
    if (!this.mUserAuth) return false;
  }

  isAuthenticated$(): Observable<boolean> {
    return authState(this.aAuth).pipe(
      tap(v => console.log('isAuthenticated$', v)),
      map(user => user ? true : false)
    );
  }

  isObservableAuthenticated(): Observable<boolean> {
    return this.mUserAuth$.pipe(map(v => v != null));
  }

  isAdmin(): boolean {
    if (!this.mUserAuth) return false;
    return this.mUserAuth.role == "admin";
  }

  isSupervisor(): boolean {
    if (!this.mUserAuth) return false;
    return this.mUserAuth.role == "supervisor";
  }

  isNormal(): boolean {
    if (!this.mUserAuth) return false;
    return this.mUserAuth.role != "admin" && this.mUserAuth.role != "supervisor";
  }

  isWilayaDesk(): boolean {
    if (!this.mUserAuth) return false;
    return this.mUserAuth.role != "wilaya-desk"
  }

  isClientSystem() {
    if (!this.mUserAuth) return false;
    return this.mUserAuth.role != "client-system"
  }

  setCurrentUserAuth(aUserAuth: UserAuth | null) {
    console.log("Auth99Service*setCurrentUserAuth", aUserAuth);
    if (aUserAuth && aUserAuth.uid) {
      //
      if (aUserAuth.active == "no") {
        signOut(this.aAuth);
        timer(1000).subscribe((v) => {
          this.toastrService.show('This account is not active !', "Connexion", { status: 'info', position: NbGlobalPhysicalPosition.BOTTOM_RIGHT, destroyByClick: true });
          this.router.navigateByUrl('/auth99')
        });
      }
      else if (aUserAuth.role == "admin" ||
              aUserAuth.role == "coordinator" ||
              aUserAuth.role == "supervisor" ||
              aUserAuth.role == "wilaya-desk" ||
              aUserAuth.role == "customer-service" ||
              aUserAuth.role == "accountant" ||
              aUserAuth.role == "client-system"
            ) {
        if (!this.mUserAuth || this.mUserAuth.uid !== aUserAuth.uid) {
          timer(1000).subscribe((v) => {
            this.toastrService.show("Welcome #" + aUserAuth.role + " | " + aUserAuth.wilaya + " | " + aUserAuth.fullName, 'Connexion', { status: aUserAuth.role == 'admin' ? 'success' : 'success', position: NbGlobalPhysicalPosition.BOTTOM_RIGHT, destroyByClick: true });
          });
        }
        this.mUserAuth = aUserAuth;
        this.mUserAuth$.next(aUserAuth);
        this.mAuthBootingComplete = true;
        this.mAuthBootingComplete$.next(true);
        //localStorage.setItem('mUserAuth', JSON.stringify(aUserAuth));
      }
      else {
        signOut(this.aAuth);
        timer(1000).subscribe((v) => {
          this.router.navigateByUrl('/pages/tarek-home-all');
        });
      }
    }
    else {
      this.mUserAuth = null;
      this.mUserAuth$.next(null);
      this.mAuthBootingComplete = true;
      this.mAuthBootingComplete$.next(true);
      signOut(this.aAuth);
      //localStorage.removeItem('mUserAuth');
      this.router.navigateByUrl('/auth99');
    }
  }

  login(email: string, password: string): Observable<string> {
    return new Observable<string>((subscriber) => {
      signInWithEmailAndPassword(this.aAuth, email, password)
        .then((result) => {
          subscriber.next(result.user.uid);
          timer(1000).subscribe((v) => {
            this.router.navigateByUrl('/pages/tarek-home-all');
          });
        })
        .catch((error) => {
          console.log();
          subscriber.error(error);
        })
        .finally(() => {
          subscriber.complete();
        });
    });
  }

  logout() {
    signOut(this.aAuth);
    timer(1000).subscribe((v) => {
      this.toastrService.show('Good bye !', "Connexion", { status: 'danger', position: NbGlobalPhysicalPosition.BOTTOM_RIGHT, destroyByClick: true });
      this.router.navigateByUrl('/auth99');
    });
  }

  loadUserData(): Observable<any> {
    return new Observable<any>((subscriber) => {
      getDoc(doc(this.aFirestore, 'users2/', this.mUserAuth.phoneNumber))
        .then((result) => {
          subscriber.next(result.data());
        })
        .catch((error) => {
          subscriber.error(error);
        })
        .finally(() => {
          subscriber.complete();
        });
    });
  }

  //ok
  mObservableOfCollectionDrivers$: Observable<any> = null;
  mBehaviorSubjectAvailableDrivers2$: BehaviorSubject<Driver[]> = null;
  getOrStartObservableOfCollectionDrivers2(): Observable<any> {
    if (!this.mObservableOfCollectionDrivers$) this.mObservableOfCollectionDrivers$ = collectionData(collection(this.aFirestore, 'drivers'), { idField: 'id' });
    return this.mObservableOfCollectionDrivers$;
  }

  mDrivers: Driver[] = [];
  getAvailableDrivers(): Driver[] {
    return this.mDrivers;
  }

  //ok
  getOrStartBehaviorSubjectAvailableDrivers2(): BehaviorSubject<Driver[]> {
    if (!this.mBehaviorSubjectAvailableDrivers2$) {
      this.mBehaviorSubjectAvailableDrivers2$ = new BehaviorSubject<Driver[]>([]);
      this.getOrStartObservableOfCollectionDrivers2()
        .pipe(
          //tap(v => console.log(v)),
          map(drivers => drivers.filter(driver => /*user.active && */this.getCurrentUserAuth()._allWilayas.includes(driver.wilaya)))
        ).subscribe((drivers: any) => {
          let aDrivers: Driver[] = [];
          drivers.forEach((document: any) => {
            const aDriver = new Driver(document);
            aDrivers.push(aDriver);
          });
          this.mBehaviorSubjectAvailableDrivers2$.next(aDrivers);
          this.mDrivers = aDrivers;
        });
    }
    return this.mBehaviorSubjectAvailableDrivers2$;
  }


}
