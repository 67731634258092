<div class="header-container">
  <div class="logo-container">
      <a (click)="toggleSidebar()" href="/" class="sidebar-toggle">
          <nb-icon icon="menu-2-outline"></nb-icon>
      </a>
      <a href="/">
        <img class="weewee-logo" src="assets/logo/Logo_Horizontal Purple Tagline.svg" alt="logo">
      </a>
      <!-- <a class="logo" href="/">WW<span class="mx-2">v0.035</span></a> -->
      <div class="themes-change">
        <nb-select placeholder="Theme" (selectedChange)="changeTheme($event)">
          <nb-option *ngFor="let theme of themes" [value]="theme.value">{{theme.name}}</nb-option>
        </nb-select>
      </div>
  </div>
</div>

<div class="header-container">
  <nb-actions size="small">
    <nb-action nbPopover="Hover Mode" nbPopoverTrigger="hover" nbPopoverPlacement="bottom" [nbPopover]="templateRef" class="control-item" class="cursor" title="le temps, c'est de l'argent" >
      {{ time }}
      <ng-template #templateRef>
        <nb-calendar class="calendar" [(date)]="date" [boundingMonth]="false"></nb-calendar>
      </ng-template>
    </nb-action>
    <nb-action class="control-item" class="cursor" (click)="searchPage()" ><nb-icon icon="search-outline"></nb-icon></nb-action>
    <nb-action class="full-screen" (click)="fullScreen()">
      <nb-icon *ngIf="!isFullScreen" icon="expand-outline"></nb-icon>
      <nb-icon *ngIf="isFullScreen" icon="collapse-outline"></nb-icon>
    </nb-action>
    <nb-action class="control-item cursor" title="Notification"
    nbPopover="Hover Mode" nbPopoverTrigger="click" nbPopoverPlacement="bottom" [nbPopover]="notificationRef">
    <nb-badge status="danger" position="top right" text="2"></nb-badge>
    <nb-icon icon="bell-outline">
    </nb-icon>
      <ng-template class="popover" #notificationRef>
        <!-- <nb-card> -->
          <nb-list>
            <nb-list-item>
              <div class="notification-item">
                <div class="notification-content">
                  <div class="title">You have a new notification</div>
                </div>
              </div>
            </nb-list-item>
            <nb-list-item>
              <div class="notification-item">
                <div class="notification-content">
                  <div class="title">You have a new notification</div>
                </div>
              </div>
            </nb-list-item>
          </nb-list>
        <!-- </nb-card> -->
      </ng-template>
    </nb-action>
    <nb-action class="control-item" class="person" >
      <nb-user
        [name]="fullName" [title]="role"
        picture="https://xsgames.co/randomusers/avatar.php?g=pixel"
        [nbContextMenu]="userMenu"
        nbContextMenuTag="user-context-menu"
        nbContextMenuTrigger="hover"
        >
        </nb-user>
    </nb-action>
  </nb-actions>
</div>
